import React from 'react'
import Accordion from '../Accordion/Accordion'
import './skills.css'
import Interpersonal from '../interpersonal_skills/Interpersonal';
import { SkillsItems } from '../../dashboard/dashboard';
export default function Skills() {
 

  return (
    <>
      <section id="skills" className="">
        <div className="container">
          <div className="d-flex align-items-center flex-column">
            <h2 className="text-center h2">Technical Skills</h2>
            <p className=" text-center skills__para">if paragraph add here else remove this tag</p>
          </div>
          <div className="row">
            {SkillsItems && SkillsItems.map((item, index) => (
              <div className="col-12 col-md-4" key={index}>
                <Accordion key={index} title={item.title} subheading={item.subheadings} />
              </div>
            ))}

          </div>
          <Interpersonal />
        </div>
      </section>
    </>
  )
}
