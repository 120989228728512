import React from "react";

export default function Accordionitem(props) {
    const {bodyRef, headerRef,handleClick,title, subheadings,keyvalue} = props;
  return (
    <React.Fragment key={keyvalue}>
      <div className="accordion-item" >
        <div
          className="accordion-item-header"
          ref={headerRef}
          onClick={handleClick}
        >
          {title}
         
        </div>
        <div className="accordion-item-body" ref={bodyRef}>
          <div className="accordion-item-body-content">
             {subheadings && subheadings.map((item, index)=>( <p key={index}><span className="fw-bold">{item.subheading} : </span>{Array.isArray(item.content)? item.content.join(", "):item.content}</p>))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
