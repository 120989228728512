import React from 'react'

export default function Card(props) {
    const {title,to,desc}=props
    return (
        <>
            <div className="card" >
                <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <h6 className="card-subtitle mb-2 text-body-secondary">{to}</h6>
                   
                {Array.isArray(desc)?    <ul>
                        
                        {desc.map((item,index)=>(
                            <li key={index}>{item}</li>

                        ))}
                    </ul>: <p className="card-text">
                {desc} 
                    </p>}
                </div>
            </div>
        </>
    )
}
