import React from 'react'
import NavBar from '../components/NavBar/Nav-Bar'
import Hero from '../components/Hero/Hero'
import Skills from '../components/skills/Skills'
import Exprience from '../components/Experience/Exprience'
import Projects from '../components/Projects/Projects'
import Tranings from '../components/tranings/Tranings'
import Education from '../components/Education/Education'
import Footer from '../components/Footer/Footer'

export default function Home() {
    return (
        <>
            <NavBar />
            <Hero />
            <Skills />
            <Exprience />
            <Projects />
            <Tranings />
            <Education />
            <Footer />
        </>
    )
}
