import React from 'react'
import { EducationalDetails } from '../../dashboard/dashboard'
export default function Education() {
    return (
        <>
            <div className="Education top__space__withbg py-4" style={{ backgroundColor: "var(--main-color)" }}>
                <div className="container " >
                    <h2 className="text-center h2 pb-3">
                        Education
                    </h2>
                    <div id='education' >
                        <div className="row row-gap-3">
                            {EducationalDetails.map((item,index)=>(
                                <div key={index} className="col-12 col-md-6">
                                <div className="card">
                                    <div className="card-body d-flex row-gap-3 flex-column">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="card-title"> {item?.degree}</h5>
                                            <h6 className="card-subtitle mb-2 text-body-secondary">{item?.CGPA}</h6>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <h6 className="card-subtitle">{item.university}</h6>
                                            <h6 className="card-subtitle mb-2 text-body-secondary">{item.date}</h6>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            ))}
                   

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
