import React, { useRef } from 'react'
import Accordionitem from '../accordionitem/Accordionitem';
export default function Accordion(props) {
  const { title, subheading, indexkey } = props;
  const headerRef = useRef(null);
  const bodyRef = useRef(null);

  const handleClick = () => {
    const activeHeader = document.querySelector('.accordion-item-header.active');

    if (activeHeader && activeHeader !== headerRef.current) {
      activeHeader.classList.remove('active');
      activeHeader.nextElementSibling.style.maxHeight = '0px';
    }

    headerRef.current.classList.toggle('active');
    const body = bodyRef.current;
    body.style.maxHeight = headerRef.current.classList.contains('active') ? `${body.scrollHeight}px` : '0px';
  };
  return (
    <>
      <div className="accordion">
        <Accordionitem keyvalue={indexkey} headerRef={headerRef} bodyRef={bodyRef} handleClick={handleClick} title={title} subheadings={subheading} />
      </div>

    </>
  )
}
