import React from 'react'
import './hero.css'
import About from '../about/About'
import { heroSection } from '../../dashboard/dashboard'
import Badge from '../Badge/Badge'
import { tags } from '../../dashboard/dashboard'
export default function Hero() {
    return (
        <div>
            <section id="main__2sections">
                <div id="hero">
                    <div className="container">
                        <div className="row row-gap-5">
                            <div className="col-12 col-lg-6 d-flex align-items-center">
                                <div className="__main">
                                    <div className="__content">
                                        <h1>{heroSection[0]?.greeting}<span className="d-block __name">{heroSection[0]?.PersonName}</span></h1>
                                        <p>{heroSection[0]?.Description}</p>
                                        {/* <span className=''>tag 1</span> */}
                                        <div className="d-flex flex-row gap-2 flex-wrap"  >
                                            {tags.map((item, index)=>(<Badge objkey={index} textColor={item.textColor} bgColor={item.bgColor} tag={item.tag}/>))
}
                                        </div>
                                        

                                    </div>
                                    <div className="__stats d-none d-lg-flex gap-1 flex-wrap">
                                        <div className="box">
                                            <h2>{heroSection[0]?.ExperienceInYears}Y.</h2>
                                            <p>Experience</p>
                                        </div>
                                        <div className="box">
                                            <h2>{heroSection[0]?.Projectscomplete}+</h2>
                                            <p>Projects Complete</p>
                                        </div>
                                        <div className="box">
                                            <h2>{heroSection[0]?.happyClients}+</h2>
                                            <p>Happy clients</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 flex-column d-flex justify-content-end align-items-center align-items-lg-end">
                                <div className="__img_box ">
                                    <img src="/images/Asad_Hanif_2021.png" alt="Asad Hanif" />
                                </div>
                                <div className="__stats d-lg-none mt-5 d-flex gap-1 flex-wrap ">
                                    <div className="box">
                                        <h2>{heroSection[0]?.ExperienceInYears}Y.</h2>
                                        <p>Experience</p>
                                    </div>
                                    <div className="box">
                                        <h2>{heroSection[0]?.Projectscomplete}+</h2>
                                        <p>Projects Complete</p>
                                    </div>
                                    <div className="box">
                                        <h2>{heroSection[0]?.happyClients}+</h2>
                                        <p>Happy clients</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <About />
            </section>
        </div>
    )
}
