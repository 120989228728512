import React from 'react'
import { InterpersonalSkills } from '../../dashboard/dashboard'
export default function Interpersonal() {
  return (
    <>
      <div className=" top__space">
        <h2 className=" text-center pb-4">Interpersonal Skills</h2>
        <div className="extra__skills rounded">

          <div className="row">
            {
              InterpersonalSkills.map((skill, index) => (

                <div key={index} className="col-6 col-md-4"><p className="fw-medium __skills">{skill}</p></div>
              ))
              
            }
          </div>
        </div>
      </div>
    </>
  )
}
