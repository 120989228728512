
export const heroSection=[
  {
    greeting:"Hello, I'm",
    PersonName:"Asad Hanif",
    Description:"I'm a Freelance UI/UX Designer and Developer based in Bahawalpur, Pakistan. I strives to build immersive and beautiful web applications through carefully crafted code and user-centric design.",
    ExperienceInYears:"3",
    Projectscomplete:"250",
    happyClients:"90",
  }
];

export const tags=[
{
  textColor:'light',// value should be dark / light or you can use any bootstrap color like info, primary, danger, warning 
  bgColor:'red', // you can give any color code, name or rgb value. color code reference https://htmlcolorcodes.com/
  tag:"any text"
},
{
  textColor:'dark',// value should be dark / light or you can use any bootstrap color like info, primary, danger, warning 
  bgColor:'rgb(192, 192, 192)',// you can give any color code, name or rgb value. color code reference https://htmlcolorcodes.com/
  tag:"any text 2sas"
},
{
  textColor:'dark',// value should be dark / light or you can use any bootstrap color like info, primary, danger, warning 
  bgColor:'#FFFF00',// you can give any color code, name or rgb value. color code reference https://htmlcolorcodes.com/
  tag:"any tex new"
},
{
  textColor:'light',// value should be dark / light or you can use any bootstrap color like info, primary, danger, warning 
  bgColor:'green',// you can give any color code, name or rgb value. color code reference https://htmlcolorcodes.com/
  tag:"any hello world"
},
{
  textColor:'light',// value should be dark / light or you can use any bootstrap color like info, primary, danger, warning 
  bgColor:'green',// you can give any color code, name or rgb value. color code reference https://htmlcolorcodes.com/
  tag:"any hello world"
},
{
  textColor:'light',// value should be dark / light or you can use any bootstrap color like info, primary, danger, warning 
  bgColor:'green',// you can give any color code, name or rgb value. color code reference https://htmlcolorcodes.com/
  tag:"any hello world"
},
{
  textColor:'light',// value should be dark / light or you can use any bootstrap color like info, primary, danger, warning 
  bgColor:'green',// you can give any color code, name or rgb value. color code reference https://htmlcolorcodes.com/
  tag:"any hello world"
},
];


export const about=[
  {
    description:["Lorem ipsum, dolor sit amet consectetur adipisicing elit. Blanditiis debitis quaerat a exercitationem repudiandae aliquam distinctio illum aperiam quia iure?","Lorem ipsum dolor sit amet, consectlaksjdalskdjsalkdjsalkdjaslkjetur adipisicing elit. Impedit obcaecati illo consectetur harum tenetur, ullam eaque excepturi eligendi mollitia offisdjlklkajsdlkasjkdlaksjdaslkdjaslkdascia.", "Lorem ipsum dolor sidkssssssssssst amet consectetur adipisicing elit. Laboriosam nesciunt accusamus odio sed quidem repellendus ipsa consequatur distinctio cumque eius!"],
    email:" asadhanif3188@gmail.com",
    phone:" +92-313-7408286",
    whatsappUrl:"https://api.whatsapp.com/send?phone=923137408286",
    fbUrl:"/",
    fbHandle:"asad hanif",
    linkedinUrl:" https://www.linkedin.com/in/asadhanif3188",
    linkedinHandle:"asadhanif3188",
    gitLabUrl:"https://gitlab.com/asadhanif3188",
    gitLabHandle:"asadhanif3188",
    githubUrl:"https://github.com/asadhanif3188",
    githubHandle:"asadhanif3188",

  }
]


export const SkillsItems = [
    {
      title: 'MLOps + LLMOps',
      subheadings: [
        { subheading: 'Tools', content: 'MLFlow, AWS SageMaker, GCP Vertex AI, RAG' },
      ],
    },
    {
      title: 'Cloud Platforms',
      subheadings: [
        { subheading: 'AWS', content: 'SageMaker, EC2, Lambda, S3, EBS, Application Load Balancer (ALB), IAM, VPC, DynamoDB, RDS MySQL' },
        { subheading: 'Microsoft Azure', content: 'Azure Data Factory, Azure Kubernetes Service (AKS), Virtual Machines, Entra ID, IAM' },
      ],
    },
    {
      title: 'Distributed Computing',
      subheadings: [
        { subheading: 'Frameworks', content: 'Apache Spark, Databricks' },
      ],
    },
    {
      title: 'CI/CD + ETL + ML Pipelines',
      subheadings: [
        { subheading: 'Tools', content: 'GitLab CI, GitHub Actions, SageMaker Pipelines, Apache AirFlow, Ascend' },
      ],
    },
    {
      title: 'Container Orchestration',
      subheadings: [
        { subheading: 'Technologies', content: 'Docker, Kubernetes, Rancher, Kubeflow' },
      ],
    },
    {
      title: 'Data Warehousing & Datalake Platforms',
      subheadings: [
        { subheading: 'Platforms', content: 'Amazon S3, Azure Data Lake Storage Gen2 (ADLSg2), Amazon Redshift, Microsoft Azure Synapse Analytics, Snowflake' },
      ],
    },
    {
      title: 'Data Ingestion and Streaming',
      subheadings: [
        { subheading: 'Tools', content: 'Apache Kafka, Amazon Kinesis' },
      ],
    },
    {
      title: 'Infrastructure as Code',
      subheadings: [
        { subheading: 'Tools', content: 'Terraform, Terragrunt' },
      ],
    },
    {
      title: 'Monitoring, Logging, and Alerting',
      subheadings: [
        { subheading: 'Monitoring', content: 'Grafana, Prometheus' },
        { subheading: 'Logging', content: 'ELK Stack' },
      ],
    },
    {
      title: 'Scripting & Databases',
      subheadings: [
        { subheading: 'Languages', content: 'Python, Bash' },
        { subheading: 'Databases', content: 'MySQL, MongoDB, PostgreSQL, Redis' },
      ],
    },
    {
      title: 'Version Control & Web Servers',
      subheadings: [
        { subheading: 'VCS', content: 'Git, GitLab, GitHub' },
        { subheading: 'Web Servers', content: 'Nginx, Apache' },
      ],
    },
    {
      title: 'Linux Administration',
      subheadings: [
        { subheading: 'OS', content: 'Ubuntu' },
      ],
    },
  ];



  export const InterpersonalSkills=[
"Teamwork",
"Problem-solving",
"Adaptability",
'Flexibility',
'Emotional Intelligence',
'Quick Learner',
'Leadership',
'Communication'
  ];


  export const Experience = [
    
         {
            role:"Sr. Consultant DevOps",
            Company: " OpenWare Business (OWB) Ltd.",
            Duration: "May, 2024 to Date",
            Description:[ "Azure infrastructure administration.",
              'Administration regarding patching and updating Linux and Windows machines.', 
            ],
        },
         {
            role:"DevOps Engineer",
            Company: "Eon Intelligence",
            Duration: "August, 2023 to July, 2024",
            Description: [
              "Proficiently devised an effective branching strategy for streamlined version control management.",
              "Skillfully imparted training to a team of 6 developers, equipping them with adeptness in version control, Docker utilization, and fostering a DevOps-oriented mindset.",
              "Expertly containerized diverse APIs, developed in Laravel, FastAPI, and React front-end, using Docker, contributing to enhanced deployment and scalability.",
              "Collaborated closely with cross-functional teams including developers, testers, and system administrators to address technical challenges.",
              "Contributed ideas and solutions to improve the development, deployment, and operational processes.",
              "Successfully provisoned infrastructure leveraging Terraform for the in-house product."
            ]
        },
    

];



export const Project = [
  {
    Project: "Call Center Analytics Solution",
    Description: [
      "Launched an advanced call center analytics platform utilizing LLMs, which processed customer sentiment analysis across 1M+ calls, leading to targeted improvements in customer satisfaction and a 15% increase in retention rates."
    ],
    to: "Freelance"
  },
  {
    Project: "Real-Time Analytics Platform with Delta Lake",
    Description: [
      "Implemented a real-time analytics platform using Delta Lake on Databricks, enabling ACID transactions and schema enforcement for reliable data processing.",
      "Developed data ingestion pipelines using Apache Spark to process streaming data from Kafka and store it in Delta Lake.",
      "Enabled time travel and versioning in Delta Lake to support data audits and historical analysis.",
      "Optimized data processing workflows to achieve significant performance improvements, reducing query times by 40%.",
      "Integrated the platform with AWS services, including S3 for storage and Redshift for analytical querying."
    ],
    to: "Hobby Projects"
  },
  {
    Project: "End-to-End Data Pipeline for Computer Vision Model Training",
    Description: [
      "Build and automate a complete data pipeline to support the training of a computer vision model.",
      "Collect and preprocess a large dataset of images (e.g., public datasets like COCO, ImageNet).",
      "Implement data validation steps to ensure data quality.",
      "Automate the data ingestion process using tools like Apache NiFi or Airflow.",
      "Store the processed data in a cloud storage solution (AWS S3, Google Cloud Storage).",
      "Implement data versioning and lineage tracking."
    ],
    to: "Hobby Projects"
  },
  {
    Project: "MLOps Pipeline for Model Training, Evaluation, and Deployment",
    Description: [
      "Create a CI/CD pipeline for the development, testing, and deployment of machine learning models, focusing on computer vision applications.",
      "Use a pre-trained computer vision model (e.g., YOLO, ResNet) and set up a training pipeline.",
      "Automate the training process using CI/CD tools like GitHub Actions or Jenkins.",
      "Implement model evaluation metrics and validation tests.",
      "Deploy the trained model to a cloud service (AWS SageMaker, Azure ML) or as a Docker container.",
      "Set up monitoring and logging for the deployed model using tools like Prometheus and Grafana."
    ],
    to: "Hobby Projects"
  },
  {
    Project: "Cloud Infrastructure Automation and Management",
    Description: [
      "Set up and manage cloud infrastructure for data processing and machine learning workflows.",
      "Use Infrastructure as Code (IaC) tools like Terraform to automate the setup of cloud resources.",
      "Implement scalable data processing solutions using AWS Lambda, Google Cloud Functions, or Azure Functions.",
      "Set up a managed database solution (e.g., Amazon RDS, Google Cloud SQL) to store metadata and model results.",
      "Ensure security and compliance by setting up appropriate access controls and data encryption."
    ],
    to: "Hobby Projects"
  },
  {
    Project: "Real-time Object Detection Pipeline",
    Description: [
      "Collect and preprocess a large dataset of images or videos containing objects of interest (e.g., people, equipment, hazards).",
      "Design and implement a data pipeline to ingest, clean, and transform data into a suitable format for model training.",
      "Explore data quality issues and implement data validation checks.",
      "Train a state-of-the-art object detection model (e.g., YOLO, Faster R-CNN) on the prepared dataset.",
      "Set up a CI/CD pipeline to automate model training, evaluation, and deployment.",
      "Deploy the model to a cloud platform (AWS, GCP, Azure) for real-time inference.",
      "Implement monitoring and alerting mechanisms to track model performance and detect anomalies.",
      "Explore different data augmentation techniques to improve model robustness.",
      "Experiment with different model architectures and hyperparameters.",
      "Evaluate the impact of different data preprocessing techniques on model performance."
    ],
    to: "Hobby Projects"
  },
  {
    Project: "Anomaly Detection in Industrial Images",
    Description: [
      "Collect and preprocess a large dataset of normal and anomalous industrial images (e.g., equipment malfunctions, safety violations).",
      "Create a data pipeline to ingest, clean, and label the data.",
      "Explore techniques for data augmentation and imbalance handling.",
      "Train an anomaly detection model (e.g., autoencoders, one-class SVM) on the normal data.",
      "Develop a pipeline to detect anomalies in new images.",
      "Implement a model retraining strategy to adapt to changing conditions.",
      "Deploy the model to a cloud platform for real-time anomaly detection.",
      "Experiment with different anomaly detection algorithms.",
      "Evaluate the performance of the model using different metrics (e.g., precision, recall, F1-score).",
      "Explore explainable AI techniques to understand the model’s decisions."
    ],
    to: "Hobby Projects"
  },
  {
    Project: "Image-based Predictive Maintenance",
    Description: [
      "Collect and preprocess a dataset of images and corresponding equipment sensor data.",
      "Create a data pipeline to extract features from images and combine them with sensor data.",
      "Train a predictive maintenance model (e.g., time series forecasting, machine learning regression) to predict equipment failures.",
      "Develop a pipeline to generate predictions based on new image and sensor data.",
      "Implement a model monitoring and retraining strategy.",
      "Deploy the model to a cloud platform for real-time predictions.",
      "Explore different feature extraction techniques for images.",
      "Evaluate the impact of different model architectures and hyperparameters.",
      "Investigate the use of transfer learning to improve model performance."
    ],
    to: "Hobby Projects"
  }
];



export const Workshops = [
  {
    Title: "MLOps with AWS - Bootcamp - Zero to Hero Series",
    place: "Udemy",
    duration: "34 hours"
  },
  {
    Title: "MLOps Bootcamp: Mastering AI Operations for Success - AIOps",
    place: "Udemy",
    duration: "39.5 hours"
  },
  {
    Title: "Data Engineering on Microsoft Azure",
    place: "YouTube",
    duration: "45 hours"
  },
  {
    Title: "Master Azure Databricks for Data Engineers",
    place: "Udemy",
    duration: "17.5 hours"
  },
  {
    Title: "Spark Streaming - Stream Processing in Lakehouse - PySpark",
    place: "Udemy",
    duration: "22.5 hours"
  },
  {
    Title: "AWS SageMaker Practical for Beginners | Build 6 Projects",
    place: "Udemy",
    duration: "16 hours"
  },
  {
    Title: "End-to-End Machine Learning: From Idea to Implementation",
    place: "Udemy",
    duration: "36 hours"
  },
  {
    Title: "Apache Kafka Series - Learn Apache Kafka for Beginners",
    place: "Udemy",
    duration: "8.5 hours"
  },
  {
    Title: "Apache Kafka Series - Kafka Connect Hands-on Learning",
    place: "Udemy",
    duration: "4.5 hours"
  },
  {
    Title: "Apache Kafka Series - Kafka Monitoring & Operations",
    place: "Udemy",
    duration: "5 hours"
  },
  {
    Title: "AWS Lambda & Serverless - Developer Guide with Hands-on Labs",
    place: "Udemy",
    duration: "28.5 hours"
  },
  {
    Title: "Data Science Workshop",
    place: "Dice Analytics, Islamabad"
  }
];





export const EducationalDetails = [
  {
    degree: "Masters of Science in Computer Science (MSCS)",
    university: "Abasyn University Islamabad Campus (AUIC)",
    date: "2021",
    CGPA: "GPA=3.64/4.00"
  },
  {
    degree: "Bachelor of Science in Telecommunication and Networks (BSTN)",
    university: "Iqra University Islamabad Campus (IUIC)",
    date: "2009",
    CGPA: "GPA=3.52/4.00"
  },
  {
    degree: "HSSC - Pre-Engineering",
    university: "Islamabad College for Boys (ICB), Islamabad",
    date: "2005",
    CGPA: ""
  },
  {
    degree: "SSC - Science",
    university: "Asif Public School",
    date: "2003",
    CGPA: ""
  }
];





