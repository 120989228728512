import React from 'react'
import { Workshops } from '../../dashboard/dashboard'
export default function Tranings() {
  return (
    <>
      <div className="tranings top__space">
        <div className="container">
          <h2 className='text-center h2 pb-3'>Workshops / Tranings</h2>
          <div className="row row-gap-3">
           {Workshops.map((item,index)=>(
            <div className="col-12 col-md-6" key={index}>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{item.Title}</h5>
                <h6 className="card-subtitle mb-2 text-body-secondary">{item.place} {item.duration ? ` - ${item.duration}` : null}
                </h6>
              </div>
            </div>

          </div>
           )) }
          </div>
        </div>
      </div>

    </>
  )
}
