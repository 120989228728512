import Home from './pages/Home.jsx';
function App() {
  return (
    <div className='App'>
    <Home/>
    </div>
  );
}

export default App;
