// import React from 'react';
import React, { useState } from 'react';
import Card from '../card/Card.jsx';
import { Project } from '../../dashboard/dashboard.js';
export default function Projects() {
//     return (
//         <>
//             <section className="top__space__withbg py-4" id='portfolio' style={{ backgroundColor: "var(--main-color)", boxShadow: "var(--main-shadow)" }}>
//                 <div className="container">
//                     <h2 className='text-center h2 pb-3'>Projects</h2>
//                     <div className="d-flex gap-3 flex-column" >
//                       {Project.map((item,index)=>(<Card key={index} title={item.Project} to={item.to} desc={item.Description} />))  }

//                     </div>
//                 </div>

//             </section>
//         </>
//     )
// }



  const [visibleProjects, setVisibleProjects] = useState(3);

 const showMoreProjects = () => {
    setVisibleProjects(prevVisible => prevVisible + 3);
  };

  return (
    <section className="top__space__withbg py-4" id="portfolio" style={{ backgroundColor: "var(--main-color)", boxShadow: "var(--main-shadow)" }}>
      <div className="container">
        <h2 className="text-center h2 pb-3">Portfolio</h2>
        <div className="d-flex gap-3 flex-column">
          {Project.slice(0, visibleProjects).map((item, index) => (
            <Card key={index} title={item.Project} to={item.to} desc={item.Description} />
          ))}
        </div>
        {visibleProjects < Project.length && (
          <div className="text-center mt-4">
            <button onClick={showMoreProjects} className="btn btn-outline-dark">
              Show More
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

