import React from 'react'
import { Experience } from '../../dashboard/dashboard'
export default function Exprience() {
  return (
    <>
      <section id="experience" className="top__space__withbg py-4" style={{ backgroundColor: "var(--main-color)", boxShadow: "var(--main-shadow)" }}>
        <div className="container">
          <h2 className="text-center h2 pb-3">Experience</h2>
          <div className="experience__main rounded ps-1 ps-md-2" >
            <div className="row">
              {Experience.map((exp, index) => (<React.Fragment key={index}><div  className="group d-flex column-gap-2 mt-2">
                <div className="col-4 ps-lg-2"><p className="fw-bold">{exp.role}</p>
                  <p className="__date">[{exp.Duration}]
                  </p>
                  <p className="fw-bold">{exp.Company}</p>
                </div>
                <div className="col-8">

                  {Array.isArray(exp.Description) ?
                    <ul>
                      {exp.Description.map((exp, index) => (<React.Fragment key={index}><li>{exp}</li></React.Fragment>))}</ul> : <p>{exp.Description}</p>}

                </div>
              </div>
                {index !== Experience.length - 1 && (
                  <span className="seprator__line"></span>
                )}
              </React.Fragment>
              ))}


            </div>
          </div>

        </div>
      </section>
    </>
  )
}
