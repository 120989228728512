import React from 'react'
import { about } from '../../dashboard/dashboard'
export default function About() {
    return (
        <>
            <div className="main__about" id="about">
                <div className="container position-relative">
                    <div  className="">
                        <div className="row about">
                            <h4 className='text-uderline pb-2 pb-lg-4'>About me</h4>

                            {/* <h2 className="fs-1 mb-3">{about[0]?.title}</h2> */}
                            {
                                Array.isArray(about[0].description)?about[0].description.map((item,index)=>(<p key={index}>{item}</p>)):<p>{about[0].description}</p>
                            }
                            


                            <h4 className='text-uderline pb-3'>Contact me</h4>

                            <div className="d-flex gap-3 flex-wrap">
                                <a target="_blank" rel="noreferrer" href={`mailto:${about[0].email}`} className="d-flex align-items-center my-auto contact__deatils">
                                    <img src="/icons/email.svg" className="social-icon" alt="email" />
                                    <p className='m-0'>&nbsp;/&nbsp;{about[0].email}</p>
                                </a>
                                <a target="_blank" rel="noreferrer" href={about[0].githubUrl} className="d-flex align-items-center my-auto contact__deatils">
                                    <img src="/icons/github-brands-solid.svg" className="social-icon" alt="github" />
                                    <p className='m-0'>&nbsp;/&nbsp;{about[0].githubHandle}</p>
                                </a>
                                <a target="_blank" rel="noreferrer" href={about[0].linkedinUrl} className="d-flex align-items-center my-auto contact__deatils">
                                    <img src="/icons/Linkedin-brands-solid.svg" className="social-icon" alt="linkedin" />
                                    <p className='m-0'>&nbsp;/&nbsp;{about[0].linkedinHandle}</p>
                                </a>
                                <a target="_blank" rel="noreferrer" href={about[0].gitLabUrl} className="d-flex align-items-center my-auto contact__deatils">
                                    <img src="/icons/gitlab-brands-solid.svg" className="social-icon" alt="git lab" />
                                    <p className='m-0'>&nbsp;/&nbsp;{about[0].githubHandle}</p>
                                </a>
                                <a target="_blank" rel="noreferrer" href={about[0].fbUrl} className="d-flex align-items-center my-auto contact__deatils">
                                    <img src="/icons/facebook-brands-solid.svg" className="social-icon" alt="facebook" />
                                    <p className='m-0'>&nbsp;/&nbsp;{about[0].fbHandle}</p>
                                </a>
                                <a target="_blank" rel="noreferrer" href={about[0].whatsappUrl} className="d-flex align-items-center my-auto contact__deatils">
                                    <img src="/icons/whatsapp.svg" className="social-icon" alt="whatsapp" />
                                    <p className='m-0'>&nbsp;/&nbsp;{about[0].phone}</p>
                                </a>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}



