import React from 'react'

export default function Badge(props) {
  return (
    <>
    <div className="d-flex gap-2" key={props.objkey}>
     <div className={`badge p-2 fs-6 rounded-1 text-${props.textColor}`} style={{backgroundColor:props.bgColor }}>{props.tag}</div>
    </div>
    </>
  )
}
