import { React, useEffect } from 'react';
// import { Link } from 'react-router-dom'
export default function NavBar() {
        useEffect(() => {

          (async () => {
            const bootstrap = await import('bootstrap/dist/js/bootstrap.bundle.min.js');
            window.bootstrap = bootstrap.default;
          })();
        }, []);
    const closeNavbar = () => {
        const myCollapse = document.getElementById('navbarNavDropdown');
        if (myCollapse) {
            // console.log(window.bootstrap);
            const bsCollapse = new window.bootstrap.Collapse(myCollapse, {
                toggle: false
               
            });
            // console.log(bsCollapse)
            bsCollapse.hide();
        }
    };


    return (
        <>
            <nav className="navbar navbar-expand-lg custom__nav__bg sticky-top">
                <div className="container">
                    <a className="navbar-brand" href="/">Asad Hanif</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#hero" onClick={closeNavbar}>Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/#about" onClick={closeNavbar}>About</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/#skills" onClick={closeNavbar}>Skills</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/#experience" onClick={closeNavbar}>Experience</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/#portfolio" onClick={closeNavbar}>Portfolio</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/#education" onClick={closeNavbar}>Education</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="btn __btn__primary" href="#" onClick={closeNavbar}>Portfolio</a>
                            </li> */}

                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}
